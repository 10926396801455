import { Button, Flex, Modal, Table, notification } from 'antd';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import AxiosService from '../../../../../common/AxiosService';
import { apis } from '../../../../../constants';
import { formatWeightText } from '../../../../../helpers';
import { CheckOutlined, CloseOutlined, ExclamationCircleOutlined, FormOutlined } from '@ant-design/icons';
import style from "./exportDetailModal.module.less";
import moment from 'moment';
import { useSelector } from 'react-redux';
import { sumBy } from 'lodash';


const ExportDetailModal = forwardRef((props, ref) => {
    const {wareHouseName} = useSelector(state => state.auth)
    const {reloadData = () => {}} = props;

    const [isModalOpen, set_isModalOpen] = useState(false);
    const [data, set_data] = useState([])
    const [status, set_status] = useState('')
    const [modal, contextHolder] = Modal.useModal();
    const [dataId, set_dataId] = useState('')

    const columns = [
      {
          title: 'Ngày nhập',
          dataIndex: 'importDate',
          key: 'importDate',
          width: 120,
          render: (text) => <div>{text}</div>,
      },
      {
          title: wareHouseName == 'X' ? 'Số dải' : 'Số cuộn',
          dataIndex: 'nsmvCoilId',
          key: 'nsmvCoilId',
          width: 175,
          render: (value) => <div className={style["important-text"]}>{value}</div>
      },
      // {
      //   title: <div><div>Ngày xuất</div><div style={{paddingLeft: '25px'}}></div></div>,
      //     dataIndex: 'deliverDate',
      //     key: 'deliverDate',
      //     width: 125,
      //     render: (deliver_date) => <div>{deliver_date}</div>
      // },
      {
          title: 'Mác thép',
          dataIndex: 'steelGrade',
          key: 'steelGrade',
          width: 125,
          render: (totalRoll, item) => totalRoll
      },
  
      {
          title: 'Chủng loại',
          dataIndex: 'steelType',
          key: 'steelType',
          width: 125,
          render: (totalRoll, item) => totalRoll
      },
      {
        title: 'Dày x rộng(mm)',
          dataIndex: 'size',
          key: 'size',
          width: 150,
          render: (totalRoll, item) => formatWeightText(item.sizeDepth) + " × " + formatWeightText(item.sizeWidth)
      },
  
      {
        title: 'Trọng lượng(kg)',
          dataIndex: 'netWeight',
          key: 'netWeight',
          width: 150,
          render: (totalNetWeight) => formatWeightText(totalNetWeight)
      },
      
   
      {
          title: 'Nhà cung cấp',
          dataIndex: 'providerName',
          key: 'providerName',
          width: 150,
          // render: (totalNetWeight) => totalNetWeight
      },
    
      ...(wareHouseName == 'X' ? [
      {
        title: 'Thành phẩm(mm)',
        dataIndex: 'finishedProduct',
        key: 'finishedProduct',
        width: 150,
      },  
      {
        title: 'Đơn hàng',
        dataIndex: 'orderCode',
        key: 'orderCode',
        width: 175,
      },  
      ...(wareHouseName == 'C' ? [
        {
          title: 'Máy xẻ',
          dataIndex: 'machineName',
          key: 'machineName',
          width: 150,
        }] : [])
      ] : []),
      {
          title: 'Ghi chú',
          width: 200,
          dataIndex: 'note',
          key: 'note',
      },
  ];
   
    useImperativeHandle(ref, () => ({
        openModal(requestId, status) {
            set_isModalOpen(true)
            loadRequestDetail(requestId)
            set_status(status)
            set_dataId(requestId)
        },
        //dùng cho TH xuất kho 1 phần
        openModalV2(record){
          set_isModalOpen(true);
          loadDetailRoll(record);
          set_status('4')
        }
    }));

    const loadRequestDetail = (requestId) => {
        let dataRequest = {
            id: requestId
        }
        AxiosService.post(apis.Url_Api_Get_Coil_Export_Request_Detail, dataRequest)
            .then(response => {
                let { data } = response;
                if (!!data && data.message === "OK") {
                    console.log(data);
                    set_data(data.data)
                }
            })
            .catch(err => console.log(err))
    }

    //hiển thị thông tin cuộn + trọng lượng được xuất kho
   const loadDetailRoll = async(record) => {
      const {idGoods, totalNetWeight, deliverDate} = record;
      try {
        const {data} = await AxiosService.post(apis.Url_Api_Post_DetailRoll,{id: idGoods});
        var result = [{...data.data, netWeight: totalNetWeight, deliverDate}];
        set_data(result)
      }
      catch(e) {
        console.log(e);
      }
   }

    const handleOk = () => {
        set_isModalOpen(false);
    };
    const handleCancel = () => {
        set_isModalOpen(false);
        set_data([])
        set_status('')
    };

    
      const requestConfirmExport = () => {
        let dataRequest = {
          "listIdDeliver" : [
            dataId
         ]
        }
        AxiosService.post(apis.Url_Api_Post_XacNhanChiThiXuatKho, dataRequest)
        .then(res => {
          let { data } = res;
          if (!!data && data.message === "OK") {
            notification.success({message: "Xác nhận thành công"})
          } else {
            notification.error({message: data.message})
          }
          reloadData()
          handleCancel()
        })
        .catch(e => notification.error({message: e.message}))
      }

    const onRequestDelele = () => {
            modal.confirm({
              title: 'Xác nhận xóa',
              icon: <ExclamationCircleOutlined />,
              content: 'Bạn chắc chắn muốn xóa chỉ thị này?',
              okText: 'Xóa',
              okType: 'danger',
              onOk : () => onsendRequestCancel(),
              cancelText: 'Đóng',
            });
      
    }

    const onsendRequestCancel = (item) => {
        let dataRequest = {
            "listCancelId" : [dataId],
          "reasonCancel" : "test hủy chị thị kho"
      }
        AxiosService.post(apis.Url_Api_Post_HuyChiThiXuatKho, dataRequest)
        .then(res => {
          let { data } = res;
          console.log(res);
          if (!!data && data.message === "OK") {
            notification.success({message: "Xóa thành công"})
          } else {
            notification.error({message: data.message})
          }
          reloadData()
          handleCancel()
        })
        .catch(e => notification.error({message: e.message}))
      }


    return <Modal title={status == '4' ? "CHI TIẾT CHỈ THỊ XUẤT KHO 1 PHẦN" : "CHI TIẾT CHỈ THỊ XUẤT KHO"} closeIcon = {false} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} className={style['modal-container']} width={'80vw'}
    
    footer={[
        <Button key="back" onClick={handleCancel}>
          Đóng
        </Button>,
         <Button key="cancel" type="danger" onClick={onRequestDelele} className={(status != '2' && status != 3) && style['hidden']}>
          Hủy
        </Button>,
        <Button type='primary' key = 'submit'
          onClick={requestConfirmExport}
          className={(status != '2') && style['hidden']}>
          Phê duyệt
        </Button>,
      ]}
    >
        <Table columns={columns} dataSource={data} className={style['table']} pagination={{ position: ['none', 'none'], pageSize: 100 }}
            rowKey="rollId" rowClassName={style['table-row']} scroll={{x: sumBy(columns,'width')}}
        />
        {contextHolder}
    </Modal>
});

export default ExportDetailModal;

const getStatusLabel = (status) => {

    switch (status) {
        case '2':
            return "Chờ duyệt";
        case '1':
            return "Hoàn thành"
        case '3':
            return "Đã duyệt/Chờ xuất";
        default:
            return "Chờ duyệt";
    }
}

